import React from "react";
import "./Testimonials.css";
import MyPicture from "../../Assets/mypicture1.jpg";
import profile from "../../Assets/profile.jpg";
import profile3 from "../../Assets/profile3.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Reviews from Clients</h5>
      <h2>Testimonials</h2>

      <Swiper
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
        className="container testimonials_container"
      >
        <SwiperSlide className="testimonial">
          <div className="client_avatar">
            <img src={MyPicture} alt="avatar1" />
          </div>
          <h5 className="client_name">Joseph Melmed</h5>
          <small className="client_review">
            This is just a template that I am very excited about because
            Swiper.js is the perfect solution to the issues I have come accross
            using other slide templates in a React app!
          </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client_avatar">
            <img src={profile} alt="avatar1" />
          </div>
          <h5 className="client_name">Joseph</h5>
          <small className="client_review">
            This is just a template that I am very excited about because
            Swiper.js is the perfect solution to the issues I have come accross
            using other slide templates in a React app!
          </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client_avatar">
            <img src={profile3} alt="avatar1" />
          </div>
          <h5 className="client_name">Chaver Shnayim</h5>
          <small className="client_review">
            This is just a template that I am very excited about because
            Swiper.js is the perfect solution to the issues I have come accross
            using other slide templates in a React app!
          </small>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Testimonials;
