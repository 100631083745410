import React from "react";
import "./Header.css";
const CTA = () => {
  return (
    <div className="cta">
      <a
        href="https://docs.google.com/document/d/1eU1NRqBcNOmake-BhUq4CfXZwgCYkh3kExTmFUKmG-c/edit?usp=sharing"
        className="btn"
        target="blank"
      >
        Download CV
      </a>
      <a href="#contact" className="btn btn-primary">
        Let's Talk
      </a>
    </div>
  );
};

export default CTA;
